<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M17.8922 17.0854C17.865 16.5566 18.2644 16.1054 18.7844 16.0776C19.3044 16.0499 19.7481 16.4562 19.7753 16.985L20.5649 32.3065C20.5921 32.8354 20.1926 33.2865 19.6726 33.3143C19.1526 33.342 18.709 32.9357 18.6817 32.4069L17.8922 17.0854Z"
        fill="inherit"/>
    <path
        d="M28.213 16.0776C27.693 16.0499 27.2493 16.4561 27.2221 16.985L26.4326 32.3064C26.4053 32.8353 26.8048 33.2865 27.3248 33.3142C27.8448 33.3419 28.2884 32.9357 28.3157 32.4068L29.1052 17.0854C29.1325 16.5565 28.733 16.1053 28.213 16.0776Z"
        fill="inherit"/>
    <path clip-rule="evenodd"
          d="M27.7429 9.31507V7.87671C27.7429 6.28795 26.4765 5 24.9143 5H22.0857C20.5235 5 19.2571 6.28795 19.2571 7.87671V9.31507H8.17857C7.52766 9.31507 7 9.85171 7 10.5137C7 11.1757 7.52766 11.7123 8.17857 11.7123H9.6232C9.39392 12.2871 9.29855 12.9285 9.37457 13.5936L12.0051 36.6073C12.2262 38.5413 13.8369 40 15.7513 40H30.7276C32.6147 40 34.2112 38.5816 34.4648 36.6799L37.5338 13.6662C37.6259 12.976 37.5322 12.3086 37.2942 11.7123H38.8214C39.4723 11.7123 40 11.1757 40 10.5137C40 9.85171 39.4723 9.31507 38.8214 9.31507H27.7429ZM24.9143 7.39726H22.0857C21.8254 7.39726 21.6143 7.61192 21.6143 7.87671V9.31507H25.3857V7.87671C25.3857 7.61192 25.1746 7.39726 24.9143 7.39726ZM33.7966 11.7123H13.1208C12.275 11.7123 11.6183 12.4623 11.7159 13.3168L14.3465 36.3305C14.4294 37.0557 15.0334 37.6027 15.7513 37.6027H30.7276C31.4353 37.6027 32.034 37.0708 32.1291 36.3577L35.1981 13.344C35.3132 12.4807 34.6533 11.7123 33.7966 11.7123Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-trash-bin-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
